/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { GridProvider } from '@trbl/react-css-grid';
import 'firebase/storage';

export const onInitialClientRender = () => {
  console.log(`© ${new Date().getFullYear()} Grand Rapids Tech`);
}

export const wrapRootElement = ({ element }) => (
  <GridProvider
    hCount={14}
    hGap="0"
    vGap="0"
    classPrefix="mw"
    breakpoints={{
      xs: 339,
      s: 639,
      m: 1211,
      l: 1599,
      xl: 2000,
    }}
  >
    {element}
  </GridProvider>
);